
export const register = `
  mutation Signup(
    $email: String!,
    $password: String!,
    $displayName: String!,
    $marketingConsent: Boolean!,
    $projectId: Int!,
  ) {
    signup(input: {
      email: $email,
      password: $password,
      displayName: $displayName,
      marketingConsent: $marketingConsent,
      projectId: $projectId,
      legacy: false
    }) {
      token
    }
  }
`;

/**
 * Login
 */
export interface LoginInput {
  input: {
    email: string,
    password: string
  }
}
export interface LoginResponse {
  login: {
    token: string,
    pre2FAToken: string
  }
}

export const login = `mutation AALogin($input: LoginInput!){
  login(input:$ input){
      token,
      pre2FAToken
  }
}`;

/**
 * 2FA Verify
 */
export interface MFAVerifyInput {
  input: {
    pre2FAToken: string,
    token: string
  }
}

export interface MFAVerifyResponse {
  mfaAuthenticatorVerify: {
    token: string
  }
}

export const verify = `mutation AAVerify2FA($input: MFAVerifyInput!){
  mfaAuthenticatorVerify(input: $input) {
      token
  }
}`

/**
 * 2FA Recover
 */
export interface MFARecoverInput {
  input: {
    pre2FAToken: string,
    recoveryCode: string
  }
}

export interface MFARecoverResponse {
  mfaAuthenticatorRecover: {
    token: string
  }
}
export const recover = `mutation AARecover2FA($input: MFARecoverInput!){
  mfaAuthenticatorRecover(input: $input) {
    token
  }
}`;

export const forgotPassword = `
  mutation ForgotPassword($email: String!) {
    passwordForgot(input: {
      email: $email
    })
  }
`;

export const updatePassword = `
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    passwordUpdate(input: {
      oldPassword: $oldPassword,
      newPassword: $newPassword
    }) {
      token
    }
  }
`;
